import React from 'react';
import {
  Box, Container, styled,
} from '@mui/material';
import { Partner, SponsorPackage } from '../../clients/server.generated';
import { shuffleArray } from '../../helpers/array';
import PartnerLogo from './PartnerLogo';
import PartnerHeader from '../layout/PartnerHeader';
import PageHeader from '../layout/PageHeader';

const LogoGrid = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'stretch',
  gap: '3rem',
  width: '100%',
});

interface Props {
  partners: Partner[];
  scaleFactor?: number;
  extensive?: boolean;
}

function PartnerGrid({ partners, scaleFactor, extensive }: Props) {
  const bronzes = shuffleArray(
    partners.filter((p) => p.package === SponsorPackage.Bronze),
  );
  const silvers = shuffleArray(
    partners.filter((p) => p.package === SponsorPackage.Silver),
  );
  const golds = shuffleArray(
    partners.filter((p) => p.package === SponsorPackage.Gold),
  );
  const platinum = shuffleArray(
    partners.filter((p) => p.package === SponsorPackage.Platinum),
  );

  const orderedPartners = [
    {
      header: 'Main Partner',
      partners: platinum,
      size: 800,
    },
    {
      header: 'Greener Partners',
      partners: golds,
      size: 380,
    },
    {
      header: 'Green Partners',
      partners: silvers,
      size: 280,
    },
    {
      header: 'Seed Partners',
      partners: bronzes,
      size: 200,
    },
  ];

  const s = scaleFactor || 1;

  return (
    <Container maxWidth="md">
      <PageHeader title="Partners" text="Our partners help us make this event possible. These will be added to the site shortly" />
      {orderedPartners.map((o, i) => (
        o.partners.length === 0 ? <Box />
          : (
            <Box sx={{ paddingBottom: '4rem' }} key={o.header}>
              <PartnerHeader
                title={o.header}
                inverse={i % 2 === 0}
                purple={i % 2 === 0}
              />
              <LogoGrid>
                {o.partners.map((p) => (
                  <PartnerLogo
                    partner={p}
                    size={o.size * s}
                    extensive={extensive}
                    key={p.id}
                  />
                ))}
              </LogoGrid>
            </Box>
          )
      ))}
    </Container>
  );
}

PartnerGrid.defaultProps = {
  extensive: false,
  scaleFactor: 1,
};

export default PartnerGrid;
